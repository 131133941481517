import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { forwardRef, useState } from "react";
import { IOSSwitch } from "../../pages/cadaastros/profile-indicate/components/IOSSwitch";
import { NumericFormat } from "react-number-format";

export const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      thousandSeparator="."
      decimalSeparator=","
      prefix="R$ "
      placeholder="R$ 0,00"
      allowNegative={false}
    />
  );
});

export const FormSenanal = ({ periodKey, data, handleSave, isLastPeriod }) => {
  const name = `${data.codLoja}.periodos.${periodKey}.loja`;
  const { control, getValues } = useFormContext();

  const [disabledInpus, setDisabledInput] = useState(
    !getValues(`temp.${name}.metaEspecial`)
  );
  return (
    <Stack
      bgcolor={"#fcfcfc"}
      component={Paper}
      variant="outlined"
      px={2}
      py={2}
    >
      <FormGroup>
        <Box gap={2} display={"grid"} gridTemplateColumns={"1fr 1fr 1fr"}>
          <FormControl>
            <Controller
              name={`temp.${name}.metaVenda`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{
                    background: "white",
                  }}
                  size="small"
                  label={"Valor Vendas"}
                  id="formatted-numberformat-input"
                  disabled={disabledInpus}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name={`temp.${name}.metaTM`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{
                    background: "white",
                  }}
                  label="Ticket Medio"
                  size="small"
                  id="formatted-numberformat-input"
                  disabled={disabledInpus}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name={`temp.${name}.metaItensPorVenda`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  sx={{
                    background: "white",
                  }}
                  disabled={disabledInpus}
                  size="small"
                  label="Item por venda"
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name={`temp.${name}.metaClientesCadastrados`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{
                    background: "white",
                  }}
                  disabled={disabledInpus}
                  type="number"
                  size="small"
                  label="Clientes cadastrados"
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name={`temp.${name}.metaQtdPedidos`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{
                    background: "white",
                  }}
                  disabled={disabledInpus}
                  type="number"
                  size="small"
                  label="Qtd Pedido"
                />
              )}
            />
          </FormControl>
          <FormControl disabled>
            <Controller
              name={`temp.${name}.diasUteis`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{
                    background: "white",
                  }}
                  disabled
                  type="number"
                  size="small"
                  label="Dias uteis"
                />
              )}
            />
          </FormControl>
        </Box>
      </FormGroup>
      <Stack
        justifyContent={"space-between"}
        alignItems={"center"}
        direction={"row"}
        mt={2}
      >
        <Box ml={"12px"}>
          <Controller
            name={`temp.${name}.metaEspecial`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                label="Meta especial"
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "14px",
                    pl: 1,
                  },
                }}
                control={
                  <IOSSwitch
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      setDisabledInput(!e.target.checked);
                      field.onChange(e);
                    }}
                  />
                }
              />
            )}
          />
        </Box>

        <Button
          onClick={() => handleSave()}
          color="aprincipal"
          size="small"
          variant="contained"
        >
          {isLastPeriod
            ? "Inserir Registro"
            : "Proximo"}
        </Button>
      </Stack>
    </Stack>
  );
};
