import { useEffect } from "react";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useAccessFilter } from "../../../hooks/useAccessFilter";
import { converterDataFormatoPadrao, converterLoja } from "../../../util/loja";
import { vendasService } from "../../../services/vendas";

export const useRadarVendas = () => {
  const {
    base,
    basesSelecionadas,
    basesDisponiveis,
    lojasSelecionadas,
    filtrarBases,
    setLojasSelecionadas,
  } = useAccessFilter();

  const [totalSoldDataBackup, setTotalSoldDataBackup] = useState(null);

  const [periodoInputInicioAux, setPeriodoInputInicioAux] = useState("");
  const [periodoInputFimAux, setPeriodoInputFimAux] = useState("");
  const [periodoInputInicio, setPeriodoInputInicio] = useState("");
  const [periodoInputFim, setPeriodoInputFim] = useState("");

  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [totalSoldData, setTotalSoldData] = useState(null);
  const [totalNumberOfSalesData, setTotalNumberOfSalesData] = useState(null);
  const [cashbackData, setCashbackData] = useState(null);
  const [periods, setPeriods] = useState(null);

  const formatTotalSoldData = (data) => {
    if (!data) {
      return setTotalSoldData(null);
    }

    const totalData = data.reduce(
      (prev, curr) => {
        return {
          currentYear: {
            vlrTotal: prev.currentYear.vlrTotal + curr.currentYear.vlrTotal,
            vlrTotalSemCadastro:
              prev.currentYear.vlrTotalSemCadastro +
              curr.currentYear.vlrTotalSemCadastro,
            vlrTotalComCadastro:
              prev.currentYear.vlrTotalComCadastro +
              curr.currentYear.vlrTotalComCadastro,

            totalPedidos:
              prev.currentYear.totalPedidos +
              (curr.currentYear.totalPedidos || 0),
            totalPedidosSemCadastro:
              prev.currentYear.totalPedidosSemCadastro +
              (curr.currentYear.totalPedidosSemCadastro || 0),
            totalPedidosComCadastro:
              prev.currentYear.totalPedidosComCadastro +
              (curr.currentYear.totalPedidosComCadastro || 0),

            vlrCusto: prev.currentYear.vlrCusto + curr.currentYear.vlrCusto,
            vlrCustoSemCadastro:
              prev.currentYear.vlrCustoSemCadastro +
              curr.currentYear.vlrCustoSemCadastro,
            vlrCustoComCadastro:
              prev.currentYear.vlrCustoComCadastro +
              curr.currentYear.vlrCustoComCadastro,

            ticketMedio: 0,
            ticketMedioComCadastro: 0,
            ticketMedioSemCadastro: 0,

            lucratividadeSemCadastro: 0,
            lucratividadeComCadastro: 0,

            representatividadeComCadastro: 0,
            representatividadeSemCadastro: 0,
          },
          pastPeriod: {
            vlrTotal: prev.pastPeriod?.vlrTotal + curr.pastPeriod?.vlrTotal,
            totalPedidos:
              prev.pastPeriod?.totalPedidos +
              (curr.pastPeriod?.totalPedidos || 0),
            ticketMedio: 0,

            vlrTotalComCadastro:
              prev.pastPeriod?.vlrTotalComCadastro +
              curr.pastPeriod?.vlrTotalComCadastro,
            totalPedidosSemCadastro:
              prev.pastPeriod?.totalPedidosSemCadastro +
              (curr.pastPeriod?.totalPedidosSemCadastro || 0),
            ticketMedioSemCadastro: 0,
            lucratividadeSemCadastro: 0,
            representatividadeSemCadastro: 0,

            vlrTotalSemCadastro:
              prev.pastPeriod?.vlrTotalSemCadastro +
              curr.pastPeriod?.vlrTotalSemCadastro,
            totalPedidosComCadastro:
              prev.pastPeriod?.totalPedidosComCadastro +
              (curr.pastPeriod?.totalPedidosComCadastro || 0),
            ticketMedioComCadastro: 0,
            lucratividadeComCadastro: 0,
            representatividadeComCadastro: 0,

            vlrCusto: prev.pastPeriod?.vlrCusto + curr.pastPeriod?.vlrCusto,
            vlrCustoSemCadastro:
              prev.pastPeriod?.vlrCustoSemCadastro +
              curr.pastPeriod?.vlrCustoSemCadastro,
            vlrCustoComCadastro:
              prev.pastPeriod?.vlrCustoComCadastro +
              curr.pastPeriod?.vlrCustoComCadastro,
          },
          pastYear: {
            vlrTotal: prev.pastYear?.vlrTotal + curr.pastYear?.vlrTotal,
            totalPedidos:
              prev.pastYear?.totalPedidos + (curr.pastYear?.totalPedidos || 0),
            ticketMedio: 0,

            vlrTotalSemCadastro:
              prev.pastYear?.vlrTotalSemCadastro +
              curr.pastYear?.vlrTotalSemCadastro,
            totalPedidosSemCadastro:
              prev.pastYear?.totalPedidosSemCadastro +
              (curr.pastYear?.totalPedidosSemCadastro || 0),
            ticketMedioSemCadastro: 0,
            lucratividadeSemCadastro: 0,
            representatividadeSemCadastro: 0,

            vlrTotalComCadastro:
              prev.pastYear?.vlrTotalComCadastro +
              curr.pastYear?.vlrTotalComCadastro,
            totalPedidosComCadastro:
              prev.pastYear?.totalPedidosComCadastro +
              (curr.pastYear?.totalPedidosComCadastro || 0),
            ticketMedioComCadastro: 0,
            lucratividadeComCadastro: 0,
            representatividadeComCadastro: 0,

            vlrCusto: prev.pastYear?.vlrCusto + curr.pastYear?.vlrCusto,
            vlrCustoSemCadastro:
              prev.pastYear?.vlrCustoSemCadastro +
              curr.pastYear?.vlrCustoSemCadastro,
            vlrCustoComCadastro:
              prev.pastYear?.vlrCustoComCadastro +
              curr.pastYear?.vlrCustoComCadastro,
          },
        };
      },
      {
        currentYear: {
          vlrTotal: 0,
          totalPedidos: 0,
          ticketMedio: 0,

          vlrTotalSemCadastro: 0,
          totalPedidosSemCadastro: 0,
          ticketMedioSemCadastro: 0,
          lucratividadeSemCadastro: 0,
          representatividadeSemCadastro: 0,

          vlrTotalComCadastro: 0,
          totalPedidosComCadastro: 0,
          ticketMedioComCadastro: 0,
          lucratividadeComCadastro: 0,
          representatividadeComCadastro: 0,
          vlrCusto: 0,
          vlrCustoSemCadastro: 0,
          vlrCustoComCadastro: 0,
        },
        pastPeriod: {
          vlrTotal: 0,
          totalPedidos: 0,
          ticketMedio: 0,

          vlrTotalComCadastro: 0,
          totalPedidosSemCadastro: 0,
          ticketMedioSemCadastro: 0,
          lucratividadeSemCadastro: 0,
          representatividadeSemCadastro: 0,

          vlrTotalSemCadastro: 0,
          totalPedidosComCadastro: 0,
          ticketMedioComCadastro: 0,
          lucratividadeComCadastro: 0,
          representatividadeComCadastro: 0,
          vlrCusto: 0,
          vlrCustoSemCadastro: 0,
          vlrCustoComCadastro: 0,
        },
        pastYear: {
          vlrTotal: 0,
          totalPedidos: 0,
          ticketMedio: 0,

          vlrTotalComCadastro: 0,
          totalPedidosSemCadastro: 0,
          ticketMedioSemCadastro: 0,
          lucratividadeSemCadastro: 0,
          representatividadeSemCadastro: 0,

          vlrTotalSemCadastro: 0,
          totalPedidosComCadastro: 0,
          ticketMedioComCadastro: 0,
          lucratividadeComCadastro: 0,
          representatividadeComCadastro: 0,
          vlrCusto: 0,
          vlrCustoSemCadastro: 0,
          vlrCustoComCadastro: 0,
        },
      }
    );

    totalData.currentYear.ticketMedio = !!totalData.currentYear.totalPedidos
      ? totalData.currentYear.vlrTotal / totalData.currentYear.totalPedidos
      : 0;
    totalData.pastPeriod.ticketMedio = !!totalData.pastPeriod?.totalPedidos
      ? totalData.pastPeriod?.vlrTotal / totalData.pastPeriod?.totalPedidos
      : 0;
    totalData.pastYear.ticketMedio = !!totalData.pastYear?.totalPedidos
      ? totalData.pastYear?.vlrTotal / totalData.pastYear?.totalPedidos
      : 0;

    totalData.currentYear.ticketMedioSemCadastro = !!totalData.currentYear
      .totalPedidosSemCadastro
      ? totalData.currentYear.vlrTotalSemCadastro /
        totalData.currentYear.totalPedidosSemCadastro
      : 0;
    totalData.pastPeriod.ticketMedioSemCadastro = !!totalData.pastPeriod
      .totalPedidosSemCadastro
      ? totalData.pastPeriod?.vlrTotalSemCadastro /
        totalData.pastPeriod?.totalPedidosSemCadastro
      : 0;
    totalData.pastYear.ticketMedioSemCadastro = !!totalData.pastYear
      .totalPedidosSemCadastro
      ? totalData.pastYear?.vlrTotalSemCadastro /
        totalData.pastYear?.totalPedidosSemCadastro
      : 0;

    totalData.currentYear.ticketMedioComCadastro = !!totalData.currentYear
      .totalPedidosComCadastro
      ? totalData.currentYear.vlrTotalComCadastro /
        totalData.currentYear.totalPedidosComCadastro
      : 0;
    totalData.pastPeriod.ticketMedioComCadastro = !!totalData.pastPeriod
      .totalPedidosComCadastro
      ? totalData.pastPeriod?.vlrTotalComCadastro /
        totalData.pastPeriod?.totalPedidosComCadastro
      : 0;
    totalData.pastYear.ticketMedioComCadastro = !!totalData.pastYear
      .totalPedidosComCadastro
      ? totalData.pastYear?.vlrTotalComCadastro /
        totalData.pastYear?.totalPedidosComCadastro
      : 0;

    totalData.currentYear.lucratividade = !!totalData.currentYear.vlrTotal
      ? ((totalData.currentYear.vlrTotal - totalData.currentYear.vlrCusto) /
          totalData.currentYear.vlrTotal) *
        100
      : 0;
    totalData.pastYear.lucratividade = !!totalData.pastYear?.vlrTotal
      ? ((totalData.pastYear?.vlrTotal - totalData.pastYear?.vlrCusto) /
          totalData.pastYear?.vlrTotal) *
        100
      : 0;
    totalData.pastPeriod.lucratividade = !!totalData.pastPeriod?.vlrTotal
      ? ((totalData.pastPeriod?.vlrTotal - totalData.pastPeriod?.vlrCusto) /
          totalData.pastPeriod?.vlrTotal) *
        100
      : 0;

    totalData.currentYear.lucratividadeSemCadastro = !!totalData.currentYear
      .vlrTotalSemCadastro
      ? ((totalData.currentYear.vlrTotalSemCadastro -
          totalData.currentYear.vlrCustoSemCadastro) /
          totalData.currentYear.vlrTotalSemCadastro) *
        100
      : 0;
    totalData.pastYear.lucratividadeSemCadastro = !!totalData.pastYear
      .vlrTotalSemCadastro
      ? ((totalData.pastYear?.vlrTotalSemCadastro -
          totalData.pastYear?.vlrCustoSemCadastro) /
          totalData.pastYear?.vlrTotalSemCadastro) *
        100
      : 0;
    totalData.pastPeriod.lucratividadeSemCadastro = !!totalData.pastPeriod
      .vlrTotalSemCadastro
      ? ((totalData.pastPeriod?.vlrTotalSemCadastro -
          totalData.pastPeriod?.vlrCustoSemCadastro) /
          totalData.pastPeriod?.vlrTotalSemCadastro) *
        100
      : 0;

    totalData.currentYear.lucratividadeComCadastro = !!totalData.currentYear
      .vlrTotalComCadastro
      ? ((totalData.currentYear.vlrTotalComCadastro -
          totalData.currentYear.vlrCustoComCadastro) /
          totalData.currentYear.vlrTotalComCadastro) *
        100
      : 0;
    totalData.pastYear.lucratividadeComCadastro = !!totalData.pastYear
      .vlrTotalComCadastro
      ? ((totalData.pastYear?.vlrTotalComCadastro -
          totalData.pastYear?.vlrCustoComCadastro) /
          totalData.pastYear?.vlrTotalComCadastro) *
        100
      : 0;
    totalData.pastPeriod.lucratividadeComCadastro = !!totalData.pastPeriod
      .vlrTotalComCadastro
      ? ((totalData.pastPeriod?.vlrTotalComCadastro -
          totalData.pastPeriod?.vlrCustoComCadastro) /
          totalData.pastPeriod?.vlrTotalComCadastro) *
        100
      : 0;

    totalData.currentYear.lucratividadeComCadastro = !!totalData.currentYear
      .vlrTotalComCadastro
      ? ((totalData.currentYear.vlrTotalComCadastro -
          totalData.currentYear.vlrCustoComCadastro) /
          totalData.currentYear.vlrTotalComCadastro) *
        100
      : 0;
    totalData.pastYear.lucratividadeComCadastro = !!totalData.pastYear
      .vlrTotalComCadastro
      ? ((totalData.pastYear?.vlrTotalComCadastro -
          totalData.pastYear?.vlrCustoComCadastro) /
          totalData.pastYear?.vlrTotalComCadastro) *
        100
      : 0;
    totalData.pastPeriod.lucratividadeComCadastro = !!totalData.pastPeriod
      .vlrTotalComCadastro
      ? ((totalData.pastPeriod?.vlrTotalComCadastro -
          totalData.pastPeriod?.vlrCustoComCadastro) /
          totalData.pastPeriod?.vlrTotalComCadastro) *
        100
      : 0;

    totalData.currentYear.representatividadeSemCadastro = !!totalData
      .currentYear.vlrTotal
      ? (totalData.currentYear.vlrTotalSemCadastro /
          totalData.currentYear.vlrTotal) *
        100
      : 0;

    totalData.pastYear.representatividadeSemCadastro = !!totalData.pastYear
      .vlrTotal
      ? (totalData.pastYear?.vlrTotalSemCadastro /
          totalData.pastYear?.vlrTotal) *
        100
      : 0;

    totalData.pastPeriod.representatividadeSemCadastro = !!totalData.pastPeriod
      .vlrTotal
      ? (totalData.pastPeriod?.vlrTotalSemCadastro /
          totalData.pastPeriod?.vlrTotal) *
        100
      : 0;

    totalData.currentYear.representatividadeComCadastro = !!totalData
      .currentYear.vlrTotal
      ? (totalData.currentYear.vlrTotalComCadastro /
          totalData.currentYear.vlrTotal) *
        100
      : 0;

    totalData.pastYear.representatividadeComCadastro = !!totalData.pastYear
      .vlrTotal
      ? (totalData.pastYear?.vlrTotalComCadastro /
          totalData.pastYear?.vlrTotal) *
        100
      : 0;

    totalData.pastPeriod.representatividadeComCadastro = !!totalData.pastPeriod
      .vlrTotal
      ? (totalData.pastPeriod?.vlrTotalComCadastro /
          totalData.pastPeriod?.vlrTotal) *
        100
      : 0;

    setTotalSoldData(totalData);
  };

  const formatNumberOfSalesData = (data) => {
    if (!data) {
      return setTotalNumberOfSalesData(null);
    }

    const totalData = data.reduce(
      (prev, curr) => {
        return {
          currentYear: {
            primeiraCompra: {
              vlrTotal:
                prev.currentYear?.primeiraCompra?.vlrTotal +
                (curr.currentYear?.primeiraCompra?.vlrTotal || 0),
              totalPedidos:
                prev.currentYear?.primeiraCompra?.totalPedidos +
                (curr.currentYear?.primeiraCompra?.totalPedidos || 0),
              totalClientes:
                prev.currentYear?.primeiraCompra?.totalClientes +
                (curr.currentYear?.primeiraCompra?.totalClientes || 0),
              vlrCusto:
                prev.currentYear?.primeiraCompra?.vlrCusto +
                (curr.currentYear?.primeiraCompra?.vlrCusto || 0),
              lucratividade: 0,
              representatividade: 0,
              ticketMedio: 0,
            },
            segundaCompra: {
              vlrTotal:
                prev.currentYear?.segundaCompra?.vlrTotal +
                (curr.currentYear?.segundaCompra?.vlrTotal || 0),
              totalPedidos:
                prev.currentYear?.segundaCompra?.totalPedidos +
                (curr.currentYear?.segundaCompra?.totalPedidos || 0),
              totalClientes:
                prev.currentYear?.segundaCompra?.totalClientes +
                (curr.currentYear?.segundaCompra?.totalClientes || 0),
              vlrCusto:
                prev.currentYear?.segundaCompra?.vlrCusto +
                (curr.currentYear?.segundaCompra?.vlrCusto || 0),
              lucratividade: 0,
              representatividade: 0,
              ticketMedio: 0,
            },
            terceiraCompra: {
              vlrTotal:
                prev.currentYear?.terceiraCompra?.vlrTotal +
                (curr.currentYear?.terceiraCompra?.vlrTotal || 0),
              totalPedidos:
                prev.currentYear?.terceiraCompra?.totalPedidos +
                (curr.currentYear?.terceiraCompra?.totalPedidos || 0),
              totalClientes:
                prev.currentYear?.terceiraCompra?.totalClientes +
                (curr.currentYear?.terceiraCompra?.totalClientes || 0),
              vlrCusto:
                prev.currentYear?.terceiraCompra?.vlrCusto +
                (curr.currentYear?.terceiraCompra?.vlrCusto || 0),
              lucratividade: 0,
              representatividade: 0,
              ticketMedio: 0,
            },
          },
          pastPeriod: {
            primeiraCompra: {
              vlrTotal:
                prev.pastPeriod?.primeiraCompra?.vlrTotal +
                (curr.pastPeriod?.primeiraCompra?.vlrTotal || 0),
              totalPedidos:
                prev.pastPeriod?.primeiraCompra?.totalPedidos +
                (curr.pastPeriod?.primeiraCompra?.totalPedidos || 0),
              totalClientes:
                prev.pastPeriod?.primeiraCompra?.totalClientes +
                (curr.pastPeriod?.primeiraCompra?.totalClientes || 0),
              vlrCusto:
                prev.pastPeriod?.primeiraCompra?.vlrCusto +
                (curr.pastPeriod?.primeiraCompra?.vlrCusto || 0),
              lucratividade: 0,
              representatividade: 0,
              ticketMedio: 0,
            },
            segundaCompra: {
              vlrTotal:
                prev.pastPeriod?.segundaCompra?.vlrTotal +
                (curr.pastPeriod?.segundaCompra?.vlrTotal || 0),
              totalPedidos:
                prev.pastPeriod?.segundaCompra?.totalPedidos +
                (curr.pastPeriod?.segundaCompra?.totalPedidos || 0),
              totalClientes:
                prev.pastPeriod?.segundaCompra?.totalClientes +
                (curr.pastPeriod?.segundaCompra?.totalClientes || 0),
              vlrCusto:
                prev.pastPeriod?.segundaCompra?.vlrCusto +
                (curr.pastPeriod?.segundaCompra?.vlrCusto || 0),
              lucratividade: 0,
              representatividade: 0,
              ticketMedio: 0,
            },
            terceiraCompra: {
              vlrTotal:
                prev.pastPeriod?.terceiraCompra?.vlrTotal +
                (curr.pastPeriod?.terceiraCompra?.vlrTotal || 0),
              totalPedidos:
                prev.pastPeriod?.terceiraCompra?.totalPedidos +
                (curr.pastPeriod?.terceiraCompra?.totalPedidos || 0),
              totalClientes:
                prev.pastPeriod?.terceiraCompra?.totalClientes +
                (curr.pastPeriod?.terceiraCompra?.totalClientes || 0),
              vlrCusto:
                prev.pastPeriod?.terceiraCompra?.vlrCusto +
                (curr.pastPeriod?.terceiraCompra?.vlrCusto || 0),
              lucratividade: 0,
              representatividade: 0,
              ticketMedio: 0,
            },
          },
          pastYear: {
            primeiraCompra: {
              vlrTotal:
                prev.pastYear?.primeiraCompra?.vlrTotal +
                (curr.pastYear?.primeiraCompra?.vlrTotal || 0),
              totalPedidos:
                prev.pastYear?.primeiraCompra?.totalPedidos +
                (curr.pastYear?.primeiraCompra?.totalPedidos || 0),
              totalClientes:
                prev.pastYear?.primeiraCompra?.totalClientes +
                (curr.pastYear?.primeiraCompra?.totalClientes || 0),
              vlrCusto:
                prev.pastYear?.primeiraCompra?.vlrCusto +
                (curr.pastYear?.primeiraCompra?.vlrCusto || 0),
              lucratividade: 0,
              representatividade: 0,
              ticketMedio: 0,
            },
            segundaCompra: {
              vlrTotal:
                prev.pastYear?.segundaCompra?.vlrTotal +
                (curr.pastYear?.segundaCompra?.vlrTotal || 0),
              totalPedidos:
                prev.pastYear?.segundaCompra?.totalPedidos +
                (curr.pastYear?.segundaCompra?.totalPedidos || 0),
              totalClientes:
                prev.pastYear?.segundaCompra?.totalClientes +
                (curr.pastYear?.segundaCompra?.totalClientes || 0),
              vlrCusto:
                prev.pastYear?.segundaCompra?.vlrCusto +
                (curr.pastYear?.segundaCompra?.vlrCusto || 0),
              lucratividade: 0,
              representatividade: 0,
              ticketMedio: 0,
            },
            terceiraCompra: {
              vlrTotal:
                prev.pastYear?.terceiraCompra?.vlrTotal +
                (curr.pastYear?.terceiraCompra?.vlrTotal || 0),
              totalPedidos:
                prev.pastYear?.terceiraCompra?.totalPedidos +
                (curr.pastYear?.terceiraCompra?.totalPedidos || 0),
              totalClientes:
                prev.pastYear?.terceiraCompra?.totalClientes +
                (curr.pastYear?.terceiraCompra?.totalClientes || 0),
              vlrCusto:
                prev.pastYear?.terceiraCompra?.vlrCusto +
                  curr.pastYear?.terceiraCompra?.vlrCusto || 0,
              lucratividade: 0,
              representatividade: 0,
              ticketMedio: 0,
            },
          },
        };
      },
      {
        currentYear: {
          primeiraCompra: {
            vlrTotal: 0,
            totalPedidos: 0,
            totalClientes: 0,
            ticketMedio: 0,
            lucratividade: 0,
            representatividade: 0,
            vlrCusto: 0,
          },
          segundaCompra: {
            vlrTotal: 0,
            totalPedidos: 0,
            totalClientes: 0,
            ticketMedio: 0,
            lucratividade: 0,
            representatividade: 0,
            vlrCusto: 0,
          },
          terceiraCompra: {
            vlrTotal: 0,
            totalPedidos: 0,
            totalClientes: 0,
            ticketMedio: 0,
            lucratividade: 0,
            representatividade: 0,
            vlrCusto: 0,
          },
        },
        pastPeriod: {
          primeiraCompra: {
            vlrTotal: 0,
            totalPedidos: 0,
            totalClientes: 0,
            ticketMedio: 0,
            lucratividade: 0,
            representatividade: 0,
            vlrCusto: 0,
          },
          segundaCompra: {
            vlrTotal: 0,
            totalPedidos: 0,
            totalClientes: 0,
            ticketMedio: 0,
            lucratividade: 0,
            representatividade: 0,
            vlrCusto: 0,
          },
          terceiraCompra: {
            vlrTotal: 0,
            totalPedidos: 0,
            totalClientes: 0,
            ticketMedio: 0,
            lucratividade: 0,
            representatividade: 0,
            vlrCusto: 0,
          },
        },
        pastYear: {
          primeiraCompra: {
            vlrTotal: 0,
            totalPedidos: 0,
            totalClientes: 0,
            ticketMedio: 0,
            lucratividade: 0,
            representatividade: 0,
            vlrCusto: 0,
          },
          segundaCompra: {
            vlrTotal: 0,
            totalPedidos: 0,
            totalClientes: 0,
            ticketMedio: 0,
            lucratividade: 0,
            representatividade: 0,
            vlrCusto: 0,
          },
          terceiraCompra: {
            vlrTotal: 0,
            totalPedidos: 0,
            totalClientes: 0,
            ticketMedio: 0,
            lucratividade: 0,
            representatividade: 0,
            vlrCusto: 0,
          },
        },
      }
    );

    totalData.currentYear.primeiraCompra.ticketMedio = !!totalData.currentYear
      .primeiraCompra.totalPedidos
      ? totalData.currentYear.primeiraCompra.vlrTotal /
        totalData.currentYear.primeiraCompra.totalPedidos
      : 0;
    totalData.currentYear.segundaCompra.ticketMedio = !!totalData.currentYear
      .segundaCompra.totalPedidos
      ? totalData.currentYear.segundaCompra.vlrTotal /
        totalData.currentYear.segundaCompra.totalPedidos
      : 0;
    totalData.currentYear.terceiraCompra.ticketMedio = !!totalData.currentYear
      .terceiraCompra.totalPedidos
      ? totalData.currentYear.terceiraCompra.vlrTotal /
        totalData.currentYear.terceiraCompra.totalPedidos
      : 0;

    totalData.pastYear.primeiraCompra.ticketMedio = !!totalData.pastYear
      .primeiraCompra.totalPedidos
      ? totalData.pastYear?.primeiraCompra?.vlrTotal /
        totalData.pastYear?.primeiraCompra?.totalPedidos
      : 0;
    totalData.pastYear.segundaCompra.ticketMedio = !!totalData.pastYear
      .segundaCompra.totalPedidos
      ? totalData.pastYear?.segundaCompra?.vlrTotal /
        totalData.pastYear?.segundaCompra?.totalPedidos
      : 0;
    totalData.pastYear.terceiraCompra.ticketMedio = !!totalData.pastYear
      .terceiraCompra.totalPedidos
      ? totalData.pastYear?.terceiraCompra?.vlrTotal /
        totalData.pastYear?.terceiraCompra?.totalPedidos
      : 0;

    totalData.pastPeriod.primeiraCompra.ticketMedio = !!totalData.pastPeriod
      .primeiraCompra.totalPedidos
      ? totalData.pastPeriod?.primeiraCompra.vlrTotal /
        totalData.pastPeriod?.primeiraCompra.totalPedidos
      : 0;
    totalData.pastPeriod.segundaCompra.ticketMedio = !!totalData.pastPeriod
      .segundaCompra.totalPedidos
      ? totalData.pastPeriod?.segundaCompra.vlrTotal /
        totalData.pastPeriod?.segundaCompra.totalPedidos
      : 0;
    totalData.pastPeriod.terceiraCompra.ticketMedio = !!totalData.pastPeriod
      .terceiraCompra.totalPedidos
      ? totalData.pastPeriod?.terceiraCompra.vlrTotal /
        totalData.pastPeriod?.terceiraCompra.totalPedidos
      : 0;

    totalData.currentYear.primeiraCompra.lucratividade = !!totalData.currentYear
      .primeiraCompra.vlrTotal
      ? ((totalData.currentYear.primeiraCompra.vlrTotal -
          totalData.currentYear.primeiraCompra.vlrCusto) /
          totalData.currentYear.primeiraCompra.vlrTotal) *
        100
      : 0;
    totalData.currentYear.segundaCompra.lucratividade = !!totalData.currentYear
      .segundaCompra.vlrTotal
      ? ((totalData.currentYear.segundaCompra.vlrTotal -
          totalData.currentYear.segundaCompra.vlrCusto) /
          totalData.currentYear.segundaCompra.vlrTotal) *
        100
      : 0;
    totalData.currentYear.terceiraCompra.lucratividade = !!totalData.currentYear
      .terceiraCompra.vlrTotal
      ? ((totalData.currentYear.terceiraCompra.vlrTotal -
          totalData.currentYear.terceiraCompra.vlrCusto) /
          totalData.currentYear.terceiraCompra.vlrTotal) *
        100
      : 0;

    totalData.pastYear.primeiraCompra.lucratividade = !!totalData.pastYear
      .primeiraCompra.vlrTotal
      ? ((totalData.pastYear?.primeiraCompra?.vlrTotal -
          totalData.pastYear?.primeiraCompra?.vlrCusto) /
          totalData.pastYear?.primeiraCompra?.vlrTotal) *
        100
      : 0;
    totalData.pastYear.segundaCompra.lucratividade = !!totalData.pastYear
      .segundaCompra.vlrTotal
      ? ((totalData.pastYear?.segundaCompra?.vlrTotal -
          totalData.pastYear?.segundaCompra?.vlrCusto) /
          totalData.pastYear?.segundaCompra?.vlrTotal) *
        100
      : 0;
    totalData.pastYear.terceiraCompra.lucratividade = !!totalData.pastYear
      .terceiraCompra.vlrTotal
      ? ((totalData.pastYear?.terceiraCompra?.vlrTotal -
          totalData.pastYear?.terceiraCompra?.vlrCusto) /
          totalData.pastYear?.terceiraCompra?.vlrTotal) *
        100
      : 0;

    totalData.pastPeriod.primeiraCompra.lucratividade = !!totalData.pastPeriod
      .primeiraCompra.vlrTotal
      ? ((totalData.pastPeriod?.primeiraCompra.vlrTotal -
          totalData.pastPeriod?.primeiraCompra.vlrCusto) /
          totalData.pastPeriod?.primeiraCompra.vlrTotal) *
        100
      : 0;
    totalData.pastPeriod.segundaCompra.lucratividade = !!totalData.pastPeriod
      .segundaCompra.vlrTotal
      ? ((totalData.pastPeriod?.segundaCompra.vlrTotal -
          totalData.pastPeriod?.segundaCompra.vlrCusto) /
          totalData.pastPeriod?.segundaCompra.vlrTotal) *
        100
      : 0;
    totalData.pastPeriod.terceiraCompra.lucratividade = !!totalData.pastPeriod
      .terceiraCompra.vlrTotal
      ? ((totalData.pastPeriod?.terceiraCompra.vlrTotal -
          totalData.pastPeriod?.terceiraCompra.vlrCusto) /
          totalData.pastPeriod?.terceiraCompra.vlrTotal) *
        100
      : 0;

    const vlrTotalCurrentYear =
      totalData.currentYear.primeiraCompra.vlrTotal +
      totalData.currentYear.segundaCompra.vlrTotal +
      totalData.currentYear.terceiraCompra.vlrTotal;

    const vlrTotalPastYear =
      totalData.pastYear?.primeiraCompra.vlrTotal +
      totalData.pastYear?.segundaCompra.vlrTotal +
      totalData.pastYear?.terceiraCompra.vlrTotal;

    const vlrTotalPastPeriod =
      totalData.pastPeriod?.primeiraCompra.vlrTotal +
      totalData.pastPeriod?.segundaCompra.vlrTotal +
      totalData.pastPeriod?.terceiraCompra.vlrTotal;

    totalData.currentYear.primeiraCompra.representatividade =
      !!vlrTotalCurrentYear
        ? (totalData.currentYear.primeiraCompra.vlrTotal /
            vlrTotalCurrentYear) *
          100
        : 0;

    totalData.currentYear.segundaCompra.representatividade =
      !!vlrTotalCurrentYear
        ? (totalData.currentYear.segundaCompra.vlrTotal / vlrTotalCurrentYear) *
          100
        : 0;

    totalData.currentYear.terceiraCompra.representatividade =
      !!vlrTotalCurrentYear
        ? (totalData.currentYear.terceiraCompra.vlrTotal /
            vlrTotalCurrentYear) *
          100
        : 0;

    totalData.pastYear.primeiraCompra.representatividade = !!vlrTotalPastYear
      ? (totalData.pastYear?.primeiraCompra?.vlrTotal / vlrTotalPastYear) * 100
      : 0;

    totalData.pastYear.segundaCompra.representatividade = !!vlrTotalPastYear
      ? (totalData.pastYear?.segundaCompra?.vlrTotal / vlrTotalPastYear) * 100
      : 0;

    totalData.pastYear.terceiraCompra.representatividade = !!vlrTotalPastYear
      ? (totalData.pastYear?.terceiraCompra?.vlrTotal / vlrTotalPastYear) * 100
      : 0;

    totalData.pastPeriod.primeiraCompra.representatividade =
      !!vlrTotalPastPeriod
        ? (totalData.pastPeriod?.primeiraCompra.vlrTotal / vlrTotalPastPeriod) *
          100
        : 0;

    totalData.pastPeriod.segundaCompra.representatividade = !!vlrTotalPastPeriod
      ? (totalData.pastPeriod?.segundaCompra.vlrTotal / vlrTotalPastPeriod) *
        100
      : 0;

    totalData.pastPeriod.terceiraCompra.representatividade =
      !!vlrTotalPastPeriod
        ? (totalData.pastPeriod?.terceiraCompra.vlrTotal / vlrTotalPastPeriod) *
          100
        : 0;
    console.log({ totalData });

    setTotalNumberOfSalesData(totalData);
  };

  const formatCashbackData = (data) => {
    if (!data) {
      return setCashbackData(null);
    }

    const totalDataBabyMais = data?.babyMais?.reduce(
      (prev, curr) => {
        return {
          currentYear: {
            vlrTotalDisponivel:
              prev?.currentYear?.vlrTotalDisponivel +
              curr?.currentYear?.vlrTotalDisponivel,
            vlrTotalVencido:
              prev?.currentYear?.vlrTotalVencido +
              curr?.currentYear?.vlrTotalVencido,
            vlrTotalUtilizado:
              prev?.currentYear?.vlrTotalUtilizado +
              curr?.currentYear?.vlrTotalUtilizado,
            totalPedidosCashbackDisponivel:
              prev?.currentYear?.totalPedidosCashbackDisponivel +
              curr?.currentYear?.totalPedidosCashbackDisponivel,
            totalPedidosCashbackVencido:
              prev?.currentYear?.totalPedidosCashbackVencido +
              curr?.currentYear?.totalPedidosCashbackVencido,
            totalPedidosCashbackUtilizado:
              prev?.currentYear?.totalPedidosCashbackUtilizado +
              curr?.currentYear?.totalPedidosCashbackUtilizado,
          },
        };
      },
      {
        currentYear: {
          vlrTotalDisponivel: 0,
          vlrTotalVencido: 0,
          vlrTotalUtilizado: 0,
          totalPedidosCashbackDisponivel: 0,
          totalPedidosCashbackVencido: 0,
          totalPedidosCashbackUtilizado: 0,
        },
      }
    );

    let totalDataBabyMaisPrime = null;

    if (data?.babyMaisPrime) {
      totalDataBabyMaisPrime = data?.babyMaisPrime?.reduce(
        (prev, curr) => {
          return {
            currentYear: {
              vlrTotalDisponivel:
                prev?.currentYear?.vlrTotalDisponivel +
                curr?.currentYear?.vlrTotalDisponivel,
              vlrTotalVencido:
                prev?.currentYear?.vlrTotalVencido +
                curr?.currentYear?.vlrTotalVencido,
              vlrTotalUtilizado:
                prev?.currentYear?.vlrTotalUtilizado +
                curr?.currentYear?.vlrTotalUtilizado,
              totalPedidosCashbackDisponivel:
                prev?.currentYear?.totalPedidosCashbackDisponivel +
                curr?.currentYear?.totalPedidosCashbackDisponivel,
              totalPedidosCashbackVencido:
                prev?.currentYear?.totalPedidosCashbackVencido +
                curr?.currentYear?.totalPedidosCashbackVencido,
              totalPedidosCashbackUtilizado:
                prev?.currentYear?.totalPedidosCashbackUtilizado +
                curr?.currentYear?.totalPedidosCashbackUtilizado,
            },
          };
        },
        {
          currentYear: {
            vlrTotalDisponivel: 0,
            vlrTotalVencido: 0,
            vlrTotalUtilizado: 0,
            totalPedidosCashbackDisponivel: 0,
            totalPedidosCashbackVencido: 0,
            totalPedidosCashbackUtilizado: 0,
          },
        }
      );
    }

    const totalData = {
      babyMais: totalDataBabyMais,
      babyMaisPrime: totalDataBabyMaisPrime,
    };
    console.log({ totalData });

    setCashbackData(totalData);
  };

  const getData = useCallback(async () => {
    const toastId = toast.loading("Esperando dados do RAD");
    setLojasSelecionadas([]);
    setIsLoading(true);
    const [response, responseNumberOfSales, responseCashback] =
      await Promise.all([
        vendasService.buscaValorVendido({
          base,
          startPeriod: periodoInputInicio,
          finalPeriod: periodoInputFim,
        }),
        vendasService.buscaVendasNoPeriodo({
          base,
          startPeriod: periodoInputInicio,
          finalPeriod: periodoInputFim,
        }),
        vendasService.buscaCashback({
          base,
          startPeriod: periodoInputInicio,
          finalPeriod: periodoInputFim,
        }),
      ]);
    setIsLoading(false);

    if (response.status !== 200) {
      toast.error("Erro ao receber dados do RAD", {
        id: toastId,
      });
      setIsLoading(false);
      setLojasDisponiveis([]);
      return;
    }

    if (response.data) {
      setTotalSoldDataBackup((prev) => ({
        ...prev,
        totalSold: response.data?.data || [],
      }));
      formatTotalSoldData(response.data?.data || []);
      if (response.data?.periods) {
        setPeriods(response.data.periods);
      } else {
        setPeriods(null);
      }
      setLojasDisponiveis(
        !!response.data?.length
          ? response.data.map((item) => converterLoja(parseInt(item.codLoja)))
          : []
      );

      toast.success("Dados recebidos", {
        id: toastId,
      });
    } else {
      setPeriods(null);
    }

    if (responseNumberOfSales.data) {
      setTotalSoldDataBackup((prev) => ({
        ...prev,
        numberOfSales: responseNumberOfSales.data,
      }));
      formatNumberOfSalesData(responseNumberOfSales.data);
    }

    if (responseCashback.data) {
      setTotalSoldDataBackup((prev) => ({
        ...prev,
        cashback: responseCashback.data,
      }));
      formatCashbackData(responseCashback.data);
    }
  }, [base, lojasDisponiveis, periodoInputInicio, periodoInputFim]);

  const defaultValueBase = basesDisponiveis.find(
    (item) => item.codBase === Number(basesSelecionadas[0])
  );

  function preencherInputPeriodo() {
    var dataHoje = new Date();
    var [inicioAno, hoje] = converterDataFormatoPadrao(dataHoje);
    setPeriodoInputInicio(inicioAno);
    setPeriodoInputInicioAux(inicioAno);

    setPeriodoInputFim(hoje);
    setPeriodoInputFimAux(hoje);
  }

  const handleFilter = () => {
    setPeriodoInputFim(periodoInputFimAux);
    setPeriodoInputInicio(periodoInputInicioAux);
  };

  const handleFiltroLoja = (lojas = []) => {
    setLojasSelecionadas(
      lojas.map((item) => ({
        label: item,
        value: item,
      }))
    );

    if (!lojas.length) {
      return formatTotalSoldData(totalSoldDataBackup?.totalSold);
    }

    const selectors = lojas.map((loja) => Number(loja.split(" ")[0]));
    const totalSoldFiltered = totalSoldDataBackup?.totalSold?.filter((item) =>
      selectors.includes(item.codLoja)
    );

    formatTotalSoldData(totalSoldFiltered);

    const cashbackFilteredBabyMais =
      totalSoldDataBackup?.cashback?.babyMais?.filter((item) =>
        selectors.includes(item.codLoja)
      );

    const cashbackFilteredBabyMaisPrime =
      totalSoldDataBackup?.cashback?.babyMaisPrime?.filter((item) =>
        selectors.includes(item.codLoja)
      );

    console.log({
      babyMais: cashbackFilteredBabyMais,
      babyMaisPrime: cashbackFilteredBabyMaisPrime,
    });

    formatCashbackData({
      babyMais: cashbackFilteredBabyMais,
      babyMaisPrime: cashbackFilteredBabyMaisPrime,
    });
  };

  useEffect(() => {
    if (base && periodoInputInicio && periodoInputFim) {
      getData();
    }
  }, [base, periodoInputInicio, periodoInputFim]);

  useEffect(() => {
    preencherInputPeriodo();
  }, []);

  return {
    isLoading,
    defaultValueBase,
    basesDisponiveis,
    basesSelecionadas,
    lojasDisponiveis,
    lojasSelecionadas,
    periodoInputInicioAux,
    periodoInputFimAux,
    totalSoldData,
    totalNumberOfSalesData,
    cashbackData,
    periods,
    filtrarBases,
    handleFiltroLoja,
    handleFilter,
    setPeriodoInputInicioAux,
    setPeriodoInputFimAux,
  };
};
