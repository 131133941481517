import { api } from "./api.v2";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAvailablePeriods: async () =>
    await api.get(`/targets/sales/available-periods-current-period`),
  getAvailableTargets: async () =>
    await api.get("/targets/sales/list-available-periods"),
  getDataPage: async () => {
    return await api.get(`goals/employeesLastPeriod`);
  },
  getData: async () => {
    return await api.get(`/targets/sales/previous-target`);
  },
  getEditData: async (id) => {
    return await api.get(`/targets/sales/${id}/to-update`);
  },
  create: async (data) => {
    return await api.post(`/targets/sales`, data);
  },
  update: async (id, data) => {
    return await api.put(`/targets/sales/${id}`, data);
  },
  getAvailableStoresAndBases: async (targetId) => {
    return await api.get(
      `/targets/sales/available-stores-and-bases/${targetId}`
    );
  },
  getTargetView: async (targetId, query) => {
    return await api.get(`/targets/sales/view/${targetId}`, {
      params: query,
    });
  },
  getPeriodTargetView: async (periodId, query) => {
    return await api.get(`/targets/sales/view/period/${periodId}`, {
      params: query,
    });
  },
};
