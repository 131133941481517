import { Autocomplete, Box, Button, Card, CardContent, Collapse, Stack, TextField, Typography } from "@mui/material";
import { ButtonExpand } from "../ExpandMore";
import { Add } from "@mui/icons-material";
import { useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { TableFuncionarios } from "./TableFuncionarios";
import { addEmployeeToStore, removeEmployeeFromTarget } from "../../util/formMetasFunctions";
import { SimpleDialogDelete } from "../SimpleDialogDelete/SimpleDialogDelete";
import { FormContext } from "../../pages/financeiro/metas/cadastro-metas";

export const CollapseFuncionarios = ({ shouldDisable, optionsAuto, item }) => {

  const {
    funcionariosNaoSelecionados,
    setFuncionariosNaoSelecionados,
  } = useContext(FormContext);

  const { getValues, setValue } = useFormContext();

  const [expandedFuncionarios, setExpandedFuncionarios] = useState(false);
  const [valueSelect, setValueSelect] = useState(null);
  const [funcToDelete, setFuncToDelete] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFuncToDelete(null);
  };

  const handleOpenDialog = (row) => {
    setFuncToDelete(row);
    setOpenDialog(true);
  };

  const handleExpandFuncionariosClick = (e) => {
    e.stopPropagation();
    setExpandedFuncionarios((old) => !old);
  };

  const handleAddFuncionario = () => {
    addEmployeeToStore(item.codLoja, valueSelect?.value, getValues, setValue)
    const newFuncionarios = [...funcionariosNaoSelecionados]
    newFuncionarios.splice(newFuncionarios.findIndex((item) => item.codFunc === valueSelect?.value.codFunc), 1)
    setFuncionariosNaoSelecionados(newFuncionarios)
    setValueSelect(null);
  };

  const handleRemoveFuncionario = () => {
    console.log({ funcToDelete, funcionariosNaoSelecionados });

    removeEmployeeFromTarget(item.codLoja, funcToDelete.codFunc, getValues, setValue);
    const newFuncionarios = [...funcionariosNaoSelecionados]
    newFuncionarios.push({
      base: funcToDelete.base,
      cargo: "",
      codFunc: funcToDelete.codFunc,
      codLoja: funcToDelete.codLoja,
      nomeFunc: funcToDelete.nomeFunc
    })
    setFuncionariosNaoSelecionados(newFuncionarios)
    handleCloseDialog();
  };

  return (
    <CardContent
      sx={{
        pt: 0,
        pb: "4px",
      }}
    >
      <Card sx={{ padding: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="flex-end"
          sx={{ cursor: "pointer" }}
          onClick={handleExpandFuncionariosClick}
        >
          <Box>
            <ButtonExpand
              handleExpandClick={() => { }}
              expanded={expandedFuncionarios}
            />
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              fontWeight={"500"}
              component={"span"}
              mb={0}
              gutterBottom
            >
              Funcionários
            </Typography>
          </Box>
        </Stack>
        <Collapse in={expandedFuncionarios}>
          <Box
            display={"flex"}
            gap={1}
            py={1}
            sx={{
              ".Mui-disabled": {
                opacity: 0.7,
                backgroundColor: "#ff7aae !important",
                color: "white",
              },
            }}
          >
            <Autocomplete
              disablePortal
              value={valueSelect}
              options={optionsAuto}
              sx={{ width: "100%" }}
              onChange={(e, newValue) => {
                setValueSelect(newValue);
              }}
              noOptionsText="Nenhum resultado encontrado"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pesquise pelo nome do funcionario"
                  size="small"
                />
              )}
            />
            <Button
              onClick={handleAddFuncionario}
              variant="contained"
              color="aprincipal"
              size="small"
              disabled={shouldDisable}
            >
              <Add fontSize="inherit" />
            </Button>
          </Box>
          <TableFuncionarios
            name={`${item.codLoja}.funcionarios`}
            handleRemove={handleOpenDialog}
          />
        </Collapse>
      </Card>

      <SimpleDialogDelete
        open={openDialog}
        onClose={handleCloseDialog}
        body={`Tem certeza que deseja remover o funcionário ${funcToDelete?.codFunc} - ${funcToDelete?.nomeFunc} da meta?`}
        onDelete={() => handleRemoveFuncionario()}
      />
    </CardContent>
  );
};
